import(/* webpackMode: "eager", webpackExports: ["TrackingProvider"] */ "/vercel/path0/app/contexts/tracking-context.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/lib/Growthbook/GrowthbookClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"adjustFontFallback\":true}],\"variableName\":\"font\"}");
